


.coindata-container h1{
  text-align: center;
  word-spacing: -8px;
  color: #3a80fe;
  text-shadow: 2px 1px 1px black;
  font-size: 71px;
  padding-top: 2px;
  padding-bottom: 23px;
  line-height: 1;
  font-weight: bold;
}
@media screen and (max-width: 768px){
.coindata-container h1{
  text-align: center;
  word-spacing: -8px;
  color: #3a80fe;
  text-shadow: 2px 1px 1px black;
  font-size: 45px;
  padding-top: 2px;
  padding-bottom: 23px;
  line-height: 1;
  font-weight: normal;
}}



.coindata-container h2{
  text-align: center;
  word-spacing: -8px;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  text-shadow: -4px 3px 0 #444343;
  font-size: 70px;
  padding-top: 20px;
  padding-bottom: 1px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: normal;
}
.coindata-container4 h1{
  text-align: center;
  word-spacing: -18px;
  color: #3a80fe;
  text-shadow: 2px 1px 1px black;
  font-size: 85px;
  padding-top: 2px;
  padding-bottom: 23px;
  line-height: 1;
  font-weight: normal;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
}
@media screen and (max-width: 768px){
  .coindata-container4 h1{
    font-size: 38px;
    word-spacing: -8px;
  }
  .coindata-container h2 {
    font-size: 38px;
    padding-top: 0px;
    text-shadow: none;
}
}
.toKmno{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
}


.content {
    max-width: 600px; /* Set max width for content */
    margin: 45px auto 0;   /* Center the content horizontally */
    padding: 20px;     /* Add some padding */
  }
  .content1 {
    margin: 1px auto 0;   /* Center the content horizontally */
    padding: 0px;     /* Add some padding */
  }
  .mrginTp{
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
 
  }

  .logo-content-top{
    margin-top: 6px;
  }


  .logo-content{
    padding: 10px;
  height: 100%;
  }
  .logo-content img{
    max-width: 100%;
    border-radius: 30px;

  }

  .logo-content1{
    padding: 0px;
    width: 180px;
    margin: 0 auto;
  }
  .logo-content1 img{
    max-width: 100%;
  }
.aboutsnek{
  background-color: #a6c2fa;

}

.about-section:where(*, *::before, *::after) {
   overflow: hidden;
}
  .coindata-container p{
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 18px;
}
  .coindata-smedia p{
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-size: 11px;
  }

  .box-fordatadx{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .inner-boxdx{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .inner-smedia{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 0 0 28%;
    gap: 10px;
  }

  @media screen and (max-width: 768px){
    .inner-smedia{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex: 0 0 100%;
      gap: 10px;
    }
  }

/* .boxedx{
 background-color: white;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    flex: 1 0 35%;
    border-color: var(--borderColor);
    border-width: 2px 2px 6px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 34px;
    border-bottom-right-radius: 34px;
 
}


@media screen and (max-width: 768px){
 
.boxedx{
  background-color: white;
     padding: 5px;
     display: flex;
     flex-direction: column;
     min-width: auto;
     border-color: var(--borderColor);
     border-width: 2px 2px 6px;
     border-top-left-radius: 30px;
     border-top-right-radius: 30px;
     border-bottom-left-radius: 34px;
     border-bottom-right-radius: 34px;
  
 }
} */


.lines > li {
  list-style: none;
  height: 4px; /* line width on a circle border */
  background-color: white;
  width: 280px; /* circle diameter */
  
  position: absolute;
  left: -10px; /* shift lines to left by border width */
  top: calc(50% - 2px); /* shift lines by half of line height */
}

.lines > li:nth-child(1) {
  transform: rotate(0deg);
}
.lines > li:nth-child(2) {
  transform: rotate(60deg);
}
.lines > li:nth-child(3) {
  transform: rotate(-60deg);
}
.boxedx span{
  display: block;
  width: 80px;
  margin: 0 auto;
}

.boxedx span img{
  max-width: 100%;
}

.boxedx span{
  font-size: 38px;
  color: #3a80fe;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  text-shadow: -4px 3px 0 #444343;
  line-height: 1;
  padding-top: 9px;
}

.socialBox{

  display: flex;
  flex-direction: column;
  width: 55px;
  align-items: center;
  justify-content: center;
}
.socialBox span{
  display: block;
  width: 24px;
}

.socialBox span img{
  max-width: 100%;
}

.about-section{
  background-color:#fff8eb;
  
  display: flex;
  flex-direction: column;
  min-width: 260px;
  border-color: var(--borderColor);
  border-width: 2px 2px 6px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 34px;
  border-bottom-right-radius: 34px;
}


.aboutsnek p{
  text-align: justify;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 6px;
  text-transform: capitalize;
  font-size: 16px;
}

.aboutsnek h3{
  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #1d1d1d;
    text-align: center;
    word-spacing: -8px;
    color: #ffffff;
    text-shadow: none;
    font-size: 88px;
  border-bottom: 2px solid #333;
  padding-top: 10px;
  padding-bottom: 20px;
}

@media screen and (max-width: 500px){
  .aboutsnek h3{
    font-size: 38px;
}

.aboutsnek p {
  text-align: justify;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 6px;
  text-transform: capitalize;
  font-size: 13px;
}
}

.Burn-wallet {
  background-color: #3a80fe;
  width: 210px;
  padding: 10px 0;
  /* margin-top: 48px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 1;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 106px;
  border-bottom-right-radius: 106px;
  border-color: var(--borderColor);
  border-width: 2px 2px 6px;
  transition: .2s cubic-bezier(.46,.03,.52,.96);
  margin: 20px auto;
  color: #444343;
  font-family: "Concert One", sans-serif;
  padding-top: 11px;
  padding-bottom: 11px;
}

.Burn-wallet:hover {
  border-width: 6px 2px 2px;
  background-color: #fff;
}

.burned-snek {
  line-height: 52px;
  margin-top: 16px;

  font-size: 55px;

  color: #3a80fe;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  text-shadow: -4px 3px 0 #444343;
}








.donut {
  --donut-size: 300px;
  --donut-border-width: 20px;
  --donut-spacing: 0;
  --donut-spacing-color: 255, 255, 255;
  --donut-spacing-deg: calc(1deg * var(--donut-spacing));
  border-radius: 50%;
  height: var(--donut-size);
  margin: 40px;
  position: relative;
  width: var(--donut-size);
}

.donut__label {
  left: 50%;
  line-height: 1.5;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.donut__label__heading {
  font-size: 24px;
  font-weight: 600;
}

.donut__label__sub {
  color: #666666;
  font-size: 14px;
  letter-spacing: 0.05em;
}

.donut__slice {
  height: 100%;
  position: absolute;
  width: 100%;
}

.donut__slice::before,
.donut__slice::after {
  border: var(--donut-border-width) solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  width: 100%;
}

.donut__slice::before {
  border-width: calc(var(--donut-border-width) + 1px);
  box-shadow: 0 0 1px 0 rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
}

.donut__slice__first {
  --first-start: 0;
}

.donut__slice__first::before {
  border-top-color: rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
  transform: rotate(calc(360deg * var(--first-start) + 45deg));
}

.donut__slice__first::after {
  border-top-color: #ff6838;
  border-right-color: rgba(255, 104, 56, calc(100 * (var(--first) - .25)));
  border-bottom-color: rgba(255, 104, 56, calc(100 * (var(--first) - .5)));
  border-left-color: rgba(255, 104, 56, calc(100 * (var(--first) - .75)));
  transform: rotate(calc(360deg * var(--first-start) + 45deg + var(--donut-spacing-deg)));
}

.donut__slice__second {
  --second-start: calc(var(--first));
  --second-check: max(calc(var(--second-start) - .5), 0);
  -webkit-clip-path: inset(0 calc(50% * (var(--second-check) / var(--second-check))) 0 0);
          clip-path: inset(0 calc(50% * (var(--second-check) / var(--second-check))) 0 0);
}

.donut__slice__second::before {
  border-top-color: rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
  transform: rotate(calc(360deg * var(--second-start) + 45deg));
}

.donut__slice__second::after {
  border-top-color: #ffc820;
  border-right-color: rgba(255, 200, 32, calc(100 * (var(--second) - .25)));
  border-bottom-color: rgba(255, 200, 32, calc(100 * (var(--second) - .5)));
  border-left-color: rgba(255, 200, 32, calc(100 * (var(--second) - .75)));
  transform: rotate(calc(360deg * var(--second-start) + 45deg + var(--donut-spacing-deg)));
}

.donut__slice__third {
  --third-start: calc(var(--first) + var(--second));
  --third-check: max(calc(var(--third-start) - .5), 0);
  -webkit-clip-path: inset(0 calc(50% * (var(--third-check) / var(--third-check))) 0 0);
          clip-path: inset(0 calc(50% * (var(--third-check) / var(--third-check))) 0 0);
}

.donut__slice__third::before {
  border-top-color: rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
  transform: rotate(calc(360deg * var(--third-start) + 45deg));
}

.donut__slice__third::after {
  border-top-color: #72a934;
  border-right-color: rgba(151, 201, 92, calc(100 * (var(--third) - .25)));
  border-bottom-color: rgba(151, 201, 92, calc(100 * (var(--third) - .5)));
  border-left-color: rgba(151, 201, 92, calc(100 * (var(--third) - .75)));
  transform: rotate(calc(360deg * var(--third-start) + 45deg + var(--donut-spacing-deg)));
}

.donut__slice__fourth {
  --fourth-start: calc(var(--first) + var(--second) + var(--third));
  --fourth-check: max(calc(var(--fourth-start) - .5), 0);
  -webkit-clip-path: inset(0 calc(50% * (var(--fourth-check) / var(--fourth-check))) 0 0);
          clip-path: inset(0 calc(50% * (var(--fourth-check) / var(--fourth-check))) 0 0);
}

.donut__slice__fourth::before {
  border-top-color: rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
  transform: rotate(calc(360deg * var(--fourth-start) + 45deg));
}

.donut__slice__fourth::after {
  border-top-color: #258bba;
  border-right-color: rgba(28, 178, 246, calc(100 * (var(--fourth) - .25)));
  border-bottom-color: rgba(28, 178, 246, calc(100 * (var(--fourth) - .5)));
  border-left-color: rgba(28, 178, 246, calc(100 * (var(--fourth) - .75)));
  transform: rotate(calc(360deg * var(--fourth-start) + 45deg + var(--donut-spacing-deg)));
}

.donut__slice__fifth {
  --fifth-start: calc(var(--first) + var(--second) + var(--third) + var(--fourth));
  --fifth-check: max(calc(var(--fifth-start) - .5), 0);
  -webkit-clip-path: inset(0 calc(50% * (var(--fifth-check) / var(--fifth-check))) 0 0);
          clip-path: inset(0 calc(50% * (var(--fifth-check) / var(--fifth-check))) 0 0);
}

.donut__slice__fifth::before {
  border-top-color: rgba(var(--donut-spacing-color), calc(100 * var(--donut-spacing)));
  transform: rotate(calc(360deg * var(--fifth-start) + 45deg));
}

.donut__slice__fifth::after {
  border-top-color: #4e0ca5;
  border-right-color: rgba(22, 133, 184, calc(100 * (var(--fifth) - .25)));
  border-bottom-color: rgba(22, 133, 184, calc(100 * (var(--fifth) - .5)));
  border-left-color: rgba(22, 133, 184, calc(100 * (var(--fifth) - .75)));
  transform: rotate(calc(360deg * var(--fifth-start) + 45deg + var(--donut-spacing-deg)));
}


.donut-conainer{
  display: flex;
  justify-content: center;
  align-items: center;
 flex-direction: row;
 flex-wrap: wrap;
}




.donut-cornd:nth-child(1) {
  flex: 0 0 34%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.donut-cornd:nth-child(2) {
  flex: 0 0 64%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:500px){

  .donut-cornd:nth-child(2) {
    flex: 1;
}
  
}

/* @media screen and (max-width:760px){
  .donut-cornd:nth-child(1) {
    flex: 1;
  }
  .donut-cornd:nth-child(2) {
    flex: 1;
  }
  
} */

.sd1{
  height: 45px;
  border-color: var(--borderColor);
  border-width: 2px 2px 6px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  position: relative;
  top: 17px;
  line-height: 2;
  display: flex;
  flex: 0 0 12%;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width:500px){
  .sd1{
   top: 0;
  }
  
}
.sd2{
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  text-align: justify;
  padding: 6px;
  flex: 0 0 88%;

}

.donut-legends{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width:500px){
  .donut-legends{
    justify-content: flex-start;
  }
}
.popcontent{
  display: flex;
  margin: 0px;
  flex: 0 0 50%;
}

@media screen and (max-width:500px){
  .popcontent{
    margin: 1px 1px 15px;
    flex: 0 0 49%;
  }
  
}
.widk3{
  word-spacing: -2px;
}
.widk1{
  width: auto;
}
@media screen and (max-width:500px){
  .widk1{
    width: 30px;
  }
}
.widk2{
  text-align: left;
  font-family: "Concert One", sans-serif;
  font-size: 65px;
  color: #3a80fe;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  line-height: 1;
  font-weight: normal;
  z-index: 1000;
  transition: all 0.3s ease-out;
  letter-spacing: -3px;
}

@media screen and (max-width:500px){

  .widk2 {
    font-size: 25px;
    letter-spacing: -1px;
  }
}

.spnmax{
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}



.tsupply {
  background-color: #ffffff;
  width: 145px;
  padding: 1px 0;
  /* margin-top: 48px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 36px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 106px;
  border-bottom-right-radius: 106px;
  border-color: var(--borderColor);
  border-width: 2px 2px 6px;
  transition: .2s cubic-bezier(.46,.03,.52,.96);
  margin: 20px auto;
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
}

.tsupply:hover {
  border-width: 6px 2px 2px;
  background-color: #fff;
}

.tsupply-snek {

  line-height: 0.2;
  font-size: 35px;
  color: #3a80fe;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  text-shadow: -4px 3px 0 #444343;
}

.address-conainer{
  padding-bottom: 50px;
  display: flex;
 align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25px;
}

@media screen and (min-width: 2560px){
  .address-conainer{

    padding-bottom: 35px;
    padding-top: 15px;
  }

}
@media screen and (max-width: 768px){
  .address-conainer{
    align-items: initial;
  }
  
}


.copy-text-box{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  min-width: 750px;
  flex-direction: row;
  border: 2px solid #444343;
  border-radius: 50px;
}

@media screen and (max-width: 768px){
 .copy-text-box{
  min-width: 352px;
}
}

.input-text-in:nth-child(1){
  flex: 1 0 80%;
  font-weight: normal;
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  line-height: 2.8;
  letter-spacing: -1px;
  overflow: hidden;
  white-space: pre;
  padding-left: 6px;
}

.button-to-copy{
  width: 95%;
    background-color: #3a80fe;
    line-height: 2;
    border-radius: 96px;
    border: 2px solid #444343;
}
.button-to-copy:hover{
  width: 90%;
  box-shadow: 0 2px 3px #444343;
  transition: all 0.1s  ease-in;

}

.input-text-in:nth-child(2){
  flex: 1 0 20%;

}
























.human-resources {
  --bg-color: #dce9ff;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.card1 {
  border-bottom-left-radius: 34px;
  border-bottom-right-radius: 34px;
  border-color: var(--borderColor);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-width: 2px 2px 6px;
  background: #dce9ff;
cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  flex: 1 0 35%;
  padding-top: 15px;
}


.card1 p{
  font-family: "Concert One", sans-serif;
  font-size: 65px;
  color: #3a80fe;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  line-height: 1;
  font-weight: normal;
  z-index: 1000;
  transition: all 0.3s ease-out;
}
h1.toKamno{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  text-align: center;
  word-spacing: -8px;
  color: #3a80fe;
  text-shadow: none;
  font-size: 88px;
}
h5.toKamno{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  text-align: center;
  word-spacing: -8px;
  color: #3a80fe;
  text-shadow: none;
  font-size: 88px;
  padding-top: 30px;
}
@media screen and (max-width: 500px){
  h1.toKamno{
    font-size:38px;
  }
  h5.toKamno{
    font-size:38px;
    padding-top: 10px;
  }
  }
@media screen and (max-width: 768px){

.card1 p{
  font-size: 35px;
 
}
}

.card1:hover {

  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.card1:hover .overlay3 {
  transform: scale(4) translateZ(0);
  
}

.card1:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card1:hover .circle:after {
  background: var(--bg-color-light);
}

.card1:hover p {
  color: #3a80fe;
}

.card1:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}



.circle {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 142px;
  height: 142px;
  display: block;
  position: absolute;
  background: var(--bg-color-light);
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.circle img {
  
  z-index: 10000;
 
  max-width: 100%;
  width: 110px;
}
.card1 img {
transition: all 0.3s ease-out;
}
.card1:hover img {
  width: 100px;
transform: translateX(15px) scale(1.005) translateZ(10px);
}
.overlay3 {
  width: 112px;
  position: absolute;
  height: 112px;
  border-radius: 50%;
  background:#f1f7ff;
  top: 17px;

  z-index: 0;
  transition: transform 0.3s ease-out;
}


.coindata-smedia{
  display: flex;
}

.coindata-creature{
  display: flex;
  flex: 0 0 20%;
  position: relative;
  left: 15px;
}
.coindata-creature img{
  max-width: 100%;
}
@media screen and (max-width: 500px){
  .coindata-creature{
    display: flex;
    flex: 0 0 55%;
  }
  }


  .logo-content-text{
    position: absolute;
    z-index: 9;
    left: 70px;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
 
  }
  .logo-content-text span{
    color: #ffffff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #1d1d1d;
    font-size: 50px;
    padding-top: 20px;
    padding-bottom: 1px;
    line-height: 1;
    font-weight: normal;
  }


  .eyes {
    position: relative;

  }
  
  .eyes div {
 
    transition: all 0.5s;
  }
  
  div.eyes div.eyeball {
    transition: all 0.5s;

  }
  
  .eyeball div:not(.hover) {
    border-radius: 1000px;
  }
  
  .eye {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border: 13.3333333333px solid #000;
  }
  
  .iris {
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
    width: 200px;
    height: 200px;
    background-color: #000;
  }
  
  .pupil {
    left: 50%;
    top: 50%;
    margin-left: -33.3333333333px;
    margin-top: -33.3333333333px;
    width: 66.6666666667px;
    height: 66.6666666667px;
    background-color: #fff;
  }
  
  /*9 hover areas*/
  .hover {
    width: 33.3%;
    height: 33.3%;
    z-index: 10;
  }
  
  /*positions*/
  .center {
    left: 50%;
    margin-left: -16.6%;
  }
  
  .top {
    top: 0;
  }
  
  .bottom {
    bottom: 0;
  }
  
  .middle {
    top: 33.3%;
  }
  
  .left {
    left: 0;
  }
  
  .right {
    right: 0;
  }
  
  /*hover animation*/
  /*top*/
  .hover.top:hover ~ .eyeball .eye .iris {
    top: 35%;
  }
  
  .hover.top:hover ~ .eyeball .eye .iris .pupil {
    top: 40%;
  }
  
  /*bottom*/
  .hover.bottom:hover ~ .eyeball .eye .iris {
    top: 65%;
  }
  
  .hover.bottom:hover ~ .eyeball .eye .iris .pupil {
    top: 60%;
  }
  
  /*middle*/
  .hover.middle:hover ~ .eyeball .eye .iris {
    top: 50%;
  }
  
  .hover.middle:hover ~ .eyeball .eye .iris .pupil {
    top: 50%;
  }
  
  /*left*/
  .hover.left:hover ~ .eyeball .eye .iris {
    left: 30%;
  }
  
  .hover.left:hover ~ .eyeball .eye .iris .pupil {
    left: 40%;
  }
  
  /*right*/
  .hover.right:hover ~ .eyeball .eye .iris {
    left: 70%;
  }
  
  .hover.right:hover ~ .eyeball .eye .iris .pupil {
    left: 60%;
  }
  
  /*cross eyes*/
  .hover.middle.center:hover ~ .eyeball.left .eye .iris {
    left: 70%;
  }
  
  .hover.middle.center:hover ~ .eyeball.right .eye .iris {
    left: 30%;
  }
  
  .hover.middle.center:hover ~ .eyeball.left .eye .iris .pupil {
    left: 80%;
  }
  
  .hover.middle.center:hover ~ .eyeball.right .eye .iris .pupil {
    left: 20%;
  }
.backimage-container{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0 0 100%;
  position: relative;
}
  .backimage-container img{
    max-width: 100%;
    }