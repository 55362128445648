
.round-btn {
    display: inline;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50%;
    float: left;
    margin: 15px 8px;
    box-shadow: 2px 2px 5px 0px rgb(82, 0, 67);
    border: 1px solid; /*border: 1px solid #622657;*/
  }
  .round-btn a {
    display: block !important;
    padding: 7px 12px;
    font-size: 18px;
    border-radius: 50%;
  }
  .round-btn .icon {
    padding: 3px;
  }
  .round-btn .icon img {
    height: 24px;
    width: 32px;
    margin-top: 6px;
  }
  .btn-facebook a {
    color: #3b5998;
    padding: 8px 13px;
  }
  .btn-linkedin a {
    color: #007bb6;
  }
  .btn-twitter a {
    color: #1c9deb;
  }
  .btn-instagram a {
    color: #dd3f5c;
  }
  .btn-whatsapp a {
    color: #155e54;
  }
  .btn-envelop a {
    color: #d6403a;
    font-size: 15px;
    padding: 9px 12px;
  }
  .standard_header .standard_social_links {
    margin-left: 1rem;
  }
  

  .footer-wrap {

    background-size: cover;
  }
  
  .footer-wrap h3 {
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  
  .footer-wrap p {
    font-size: 13px;
    line-height: 24px;
    color: #b3b3b3;
    margin-top: 15px;
    text-align: left;
  }
  
  .footer-wrap p a {
    color: #fff;
    text-decoration: underline;
    font-style: italic;
  }
  
  .footer-wrap p a:hover {
    text-decoration: none;
    color: #ff7800;
  }
  .footer-links li {
    text-align: left;
  }

  .footer-category li{
    text-align: left;
  }
  
  .footer-links li a {
    font-size: 13px;
    line-height: 30px;
    color: #ccc;
    text-decoration: none;
  }
  
  .footer-links li:before {
    content: "\f105";
    font-family: "FontAwesome";
    padding-right: 10px;
    color: #ccc;
  }
  
  .footer-category li a {
    font-size: 13px;
    line-height: 30px;
    color: #ccc;
    text-decoration: none;
  }
  
  .footer-category li:before {
    content: "\f105";
    font-family: "FontAwesome";
    padding-right: 10px;
    color: #b3b3b3;
  }
  
  .address {
    color: #b3b3b3;
    font-size: 14px;
    position: relative;
    padding-left: 30px;
    line-height: 30px;
  }
  
  .address:before {
    content: "\f277";
    font-family: "FontAwesome";
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .info a {
    color: #b3b3b3;
    font-size: 14px;
    line-height: 30px;
    font-weight: normal;
  }
  
  .fa-phone:before {
    content: "\f095";
  }
  
  .info a {
    color: #b3b3b3;
    font-size: 14px;
    line-height: 30px;
    font-weight: normal;
  }
  
  .fa-fax:before {
    content: "\f1ac";
  }
  
  .copyright {
    border-top: 1px solid #111;
    font-size: 14px;
    color: #ccc;
    padding-top: 15px;
    text-align: center;
    padding-bottom: 15px;
    background: #222;
  }
  footer .second_class {
    border-bottom: 0px solid #444;
    padding-bottom: 25px;
  }
  footer .first_class {
    padding-bottom: 21px;
    border-bottom: 0px solid #444;
  }
  footer .first_class p,
  footer .first_class h3 {
    margin: 0 0;
  }
  footer {
    background: #111111;
    margin-top: 31px;
    padding-bottom: 50px;
  }
  
  footer .newsletter input[type="text"] {
    width: 100%;
    background: #fff;
    color: #333;
    border: 1px solid #222;
    padding: 14px 20px;
    border-radius: 50px;
    margin-top: 12px;
  }
  
  .newsletter .newsletter_submit_btn {
    background: #fff;
    position: absolute;
    right: 30px;
    border: 0;
    top: 26px;
    font-size: 17px;
    color: #333;
  }
  
  footer .second_class_bdr {
    padding-top: 25px;
    border-top: 1px solid #222;
  }
  
  footer .btn-facebook a {
    padding: 6px 14px !important;
  }
  
  footer .btn-envelop a {
    color: #d6403a;
    font-size: 15px;
    padding: 12px 12px;
  }
  
  footer .round-btn a {
    padding: 6px 12px;
  }
  
  footer .round-btn {
    box-shadow: 2px 2px 5px 0px #222 !important;
  }
  
  footer .round-btn {
    margin: 15px 4px;
  }
  
  footer dl,
  ol,
  ul {
    padding-left: 5px;
  }
  footer li {
    list-style: none;
  }
  
  @media (max-width: 768px) {
    .footer-wrap h3 {
      margin-top: 27px;
    }
  
    footer .round-btn {
      margin: 15px 4px;
    }
  }
  @media (max-width: 320px) {
    .copyright {
      font-size: 13px;
    }
  }


  .footer-logo {
    width: 150px;
  }
  .footer-logo img{
    max-width: 100%;
  }
  
  .menue-box2 h6 {
    line-height: 2;
    margin-bottom: 0;
    color: #3a80fe;
    text-shadow: -4px 3px 0 #444343;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #1d1d1d;
    font-size: 113px;
    text-align: left;
}
@media screen and (max-width:500px){
  .menue-box2 h6 {
    line-height: 1;
    margin-bottom: 0;
    color: #3a80fe;
    text-shadow: 0px 0px 0 #0000;
   
    font-size: 55px;
    text-align: center;
}
}

.join-community1{
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 7px;
}
.card3 {
  border-bottom-left-radius: 34px;
  border-bottom-right-radius: 34px;
  border-color: var(--borderColor);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-width: 0;

  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  transition: all 0.3s ease-out;
  text-decoration: none;
  flex: 1 0 35%;
  padding-top: 15px;
}
.join-community-button span.crone{
  width: 37px;
  position: relative;
  top: 1px;
  left: -10px;
  background: #030202;
  border-radius: 100px;
  display: flex;
  border: 1px solid #000;
}

.join-community-button span.crone img{
 max-width: 100%;
}


.card3:hover {
   background-color: #0000;
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}
.card3 .circle{
  transition: all 0.3s ease-out;
}
.card3 .circle img{
  transition: all 0.3s ease-out;
}


.join-community h6{
  -webkit-text-stroke-color: #000000;
  word-spacing: -8px;
  text-shadow: -4px 3px 0 #444343;
  color: #3a80fe;
  font-size: 45px;
  text-align: right;
  -webkit-text-stroke-width: 1px;
}

.join-community span{
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #1d1d1d;
  word-spacing: -8px;
  color: #fff;
  text-shadow: none;
  font-size: 45px;
  text-align: right;
  display: block;
}

.join-community-button{
  background-color: #3a80fe;
  /* width: 230px; */
  padding: 6px 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  line-height: 1;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 106px;
  border-bottom-right-radius: 106px;
  border-color: #454444;
  border-width: 2px 2px 6px;
  transition: .2s cubic-bezier(.46,.03,.52,.96);
  margin: 20px auto;
  color: #fff;
  font-family: "Concert One", sans-serif;
  padding-top: 11px;
  padding-bottom: 11px;
  float: right;
  text-transform: uppercase;
  text-shadow: -4px 3px 0 #444343;
  color: #ffffff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
}

.join-community-button:hover{
  border-color: #dae7fd;
}

.join-community-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.circle3 {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle3 img {
  
  z-index: 10000;
 
  max-width: 100%;
  width: 85px;
}

.menue-box2{
  padding-bottom: 20px;
}