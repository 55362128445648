.navigation-box{
  transition: all 1s ease 0s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  z-index:9999;
  position: relative;

}
.menue-box:nth-child(1){
  text-align: right;
  display: flex;
  flex-direction: column;

  align-items: center;
}
.menue-box:nth-child(1) span{
  text-shadow: -4px 3px 0 #444343;
  color: #3a80fe;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  line-height: 1;
  text-align: center;
}
.menue-box:nth-child(1) h1 span{

  letter-spacing: -5px;
}
@media screen and (max-width: 500px){
  .menue-box:nth-child(1) span{
text-align: center;
  }
  .menue-box:nth-child(1) h1 span{
    position: relative;
    top: 15px;
    letter-spacing: 0px;
  }
}
.menue-box:nth-child(2){
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;

  right: 0;
}

.menue-box h1 {
  display: inline-block;
  line-height: 1;
  margin-bottom: 0;
  color: #3a80fe;
  text-shadow: -4px 3px 0 #444343;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1d1d1d;
  position: relative;
  top: -22px;
}
@media screen and (min-width: 2560px){
  .menue-box h1 {
      padding: 76px;
  }
}

.navigation-box:nth-child(1){
  font-size: 50px;
  padding: 1px 10px 10px;
  top:0;
}
@media screen and (max-width: 768px){
  .menue-box:nth-child(1){
   
      padding: 2px;
  }
}
#root{
  overflow: hidden;
}

:where(*, *::before, *::after) {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
  
}




  h1 {
    text-align: center;
    word-spacing: -8px;
    color: #3a80fe;
    text-shadow: 2px 1px 1px black;
    font-size: 50px;
  }

  p{
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;

  }

  .btn-content{
    right: 26px;
    width: 40px;
    height: 44px;
    display: flex;
    align-items: center;
    background-color: var(--backgroundWhiteButtonColor);
    cursor: pointer;
    border-color: var(--borderColor);
    border-width: 2px 2px 6px;
    border-top-left-radius: 102px;
    border-top-right-radius: 102px;
    border-bottom-left-radius: 106px;
    border-bottom-right-radius: 106px;
    cursor: pointer;
    z-index: 100;
    transition: opacity 0.25s ease;
    float: right;
    position: relative;
  }

  .btn-content span img{
    position: relative;
    background-color: #fff;
    border-radius: 19px;
    left: -4px;
    top: -7px;
  }
  
  .button_container {
    right: 22px;
    width: 40px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--backgroundWhiteButtonColor);
    cursor: pointer;
    border-color: var(--borderColor);
    border-width: 2px 2px 6px;
    border-top-left-radius: 102px;
    border-top-right-radius: 102px;
    border-bottom-left-radius: 106px;
    border-bottom-right-radius: 106px;
    cursor: pointer;
    z-index: 9999;
    transition: opacity 0.25s ease;
    position: relative;
  }
  .button_container:hover {
    opacity: 0.7;
  }
  .button_container.active .top {
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: #FFF;
  }
  .button_container.active .middle {
    opacity: 0;
    background: #FFF;
  }
  .button_container.active .bottom {
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #FFF;
  }
  .button_container span {
    background: #1abc9c;
    border: none;
    height: 3px;
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;
  }
  .button_container span:nth-of-type(2) {
    top: 7px;
  }
  .button_container span:nth-of-type(3) {
    top: 15px;
  }

  .button_container .bigBtn2{
    display: none;
   }

   .bigBtn,.bigBtn2{

    font-size: 34px;
    position: relative;
    top: -2px;
   }

  .button_container.active .bigBtn{
   display: none;
  }
  .button_container.active .bigBtn2{
    display: block;
   }
  
  .overlay {
    position: fixed;
    background: #000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    overflow: hidden;
  }
  .overlay.open {
    opacity: 0.8;
    visibility: visible;
    height: 100%;
  }
  .overlay.open li {
    animation: fadeInRight 0.5s ease forwards;
    animation-delay: 0.35s;
  }
  .overlay.open li:nth-of-type(2) {
    animation-delay: 0.4s;
  }
  .overlay.open li:nth-of-type(3) {
    animation-delay: 0.45s;
  }
  .overlay.open li:nth-of-type(4) {
    animation-delay: 0.5s;
  }
  .overlay nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    text-align: center;
  }
  .overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
  }
  .overlay ul li {
    display: block;
    height: 25%;
    height: calc(100% / 4);
    min-height: 50px;
    position: relative;
    opacity: 0;
  }
  .overlay ul li a {

    position: relative;
    color: #FFF;
    text-decoration: none;
    overflow: hidden;
  }
  .overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
    width: 100%;
  }
  .overlay ul li a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    transform: translateX(-50%);
    height: 3px;
    background: #FFF;
    transition: 0.35s;
  }
  
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }


  .main-nav {
    display: -webkit-flex;
    display: flex;
  }
  .main-nav li:first-child {
    margin-right: auto;
  }
  .main-nav a {
    margin: 10px;
    border-radius: 5px;
    background: #60b99a;
    color: #fff;
    display: block;
    padding: 15px;
    text-decoration: none;
  }
  .main-nav .logo {
    background: #4584b1;
  }


  .menue-box:before,  .menue-box:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    }
  


/* 

.menue-box h1:after {
  content: attr(data-shadow);
  position: absolute;
  z-index: -1;
  height: 100%;
  top: 0.2em;
  left: 0.2em;
  text-shadow: none;
  background-image: linear-gradient(45deg, transparent 45%, rgb(0, 0, 0,1) 45%, rgb(0, 0, 0,1) 55%, transparent 0);
  
  background-size: 0.05em 0.05em;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shad-anim 15s linear infinite;
}

@keyframes shad-anim {
  0% {
    background-position: 0 0;
  }
  0% {
    background-position: 100% -100%;
  }
}

   */


  